import * as React from "react";
import { graphql, PageProps } from "gatsby";
import HeroPContainer from "../../containers/HeroPContainer/HeroPContainer";
import { HeroPType } from "../../assets/Types/AcfGroupTypes/HeroP.type";
import VsFaqPContainer from "../../containers/VsFaqPContainer/VsFaqPContainer";
import SwitchingCtaPContainer from "../../containers/SwitchingCtaPContainer/SwitchingCtaPContainer";
import { ContentFaqsPType } from "../../assets/Types/AcfGroupTypes/VsFaqPContainer.type";
import { TestimonialBannerType } from "../../assets/Types/AcfGroupTypes/TestimonialBanner.type";
import { YoastSEOType } from "../../assets/Types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import TestinmonialBannerPContainer from "../../containers/TestinmonialBannerPContainer/TestinmonialBannerPContainer";
import FeaturesModulePContainer from "../../containers/FeaturesModulePContainer/FeaturesModulePContainer";
import { FeaturesModulePType } from "../../assets/Types/AcfGroupTypes/FeaturesModuleP/FeaturesModuleP.type";
import { SwitchingCtaPType } from "../../assets/Types/AcfGroupTypes/SwitchingCtaP.type";
import { CompetitorHeroPType } from "../../assets/Types/AcfGroupTypes/CompetitorHeroP.type";
import CompetitorHeroPContainer from "../../containers/CompetitorHeroPContainer/CompetitorHeroPContainer";
import { CompetitorTablePType } from "../../assets/Types/AcfGroupTypes/CompetitorTableP.type";
import CompetitorTablePContainer from "../../containers/CompetitorTablePContainer/CompetitorTablePContainer";
import useOnUserIntent from "../../hooks/useOnUserIntent";
import LayoutWithLargeFooterCta from "../../layouts/LayoutWithLargeFooterCta";
import { deIndexSeo } from "../../utils/deIndexSeo";

type DataProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      heroP: HeroPType;
      testimonialBannerP: TestimonialBannerType;
      competitorHeroP: CompetitorHeroPType;
      competitorTableP: CompetitorTablePType;
      featuresModuleP: FeaturesModulePType;
      switchingCtaP: SwitchingCtaPType;
      contentFaqsP: ContentFaqsPType;
      userIntent: Queries.WpFAQTemplate_Userintent;
    };
    title: string;
  };
};

const CompetitorPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const { wpPage } = data;
  const {
    template: {
      heroP,
      competitorHeroP,
      competitorTableP,
      testimonialBannerP,
      featuresModuleP,
      switchingCtaP,
      contentFaqsP,
      userIntent,
    },
    title,
  } = wpPage;

  useOnUserIntent(userIntent);

  const hasCompetitorList = competitorHeroP.competitorList;
  const hasTestimonialBanner =
    testimonialBannerP.testimonialBannerAuthor &&
    testimonialBannerP.testimonialBannerQuote;

  const hasFaqs =
    contentFaqsP.contentFaqsList?.length > 0 &&
    contentFaqsP.contentFaqsSubcopy &&
    contentFaqsP.contentFaqsTitle &&
    true;

  return (
    <LayoutWithLargeFooterCta pathname={location.pathname}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <HeroPContainer props={heroP} page="Vs" />
      {hasCompetitorList && (
        <CompetitorHeroPContainer competitorHeroP={competitorHeroP} />
      )}
      {competitorTableP && (
        <CompetitorTablePContainer competitorTableP={competitorTableP} />
      )}
      {hasTestimonialBanner && (
        <TestinmonialBannerPContainer testimonialBannerP={testimonialBannerP} />
      )}
      <FeaturesModulePContainer
        props={featuresModuleP}
        page={title}
        withPaddingB={true}
        withPaddingT={true}
        isCompetitor={true}
      />
      <SwitchingCtaPContainer switchingCtaP={switchingCtaP} />
      {hasFaqs && (
        <VsFaqPContainer contentFaqsP={contentFaqsP} isVsContent={false} />
      )}
    </LayoutWithLargeFooterCta>
  );
};

export const GET_COMPETITOR_TEMPLATE_INFO = graphql`
  query CompetitorTemplateQuery($id: String) {
    wpPage(id: { eq: $id }) {
      id
      ...YoastSEOFragment
      template {
        templateName
        ... on WpCompetitorTemplate {
          ...CompetitorUserIntentFragment
          templateName
          heroP {
            heroTitle
            heroSubcopy
            heroInputPlaceholder
            heroCcText
            heroButtonText
            heroButtonLink
          }
          competitorHeroP {
            competitorList {
              subcopy
              title
              image {
                altText
                mediaItemUrl
                localFile {
                  publicURL
                }
              }
            }
          }
          competitorTableP {
            competitorTableHeaders {
              text
              image {
                altText
                mediaItemUrl
                localFile {
                  publicURL
                }
              }
              imageIcon {
                mediaItemUrl
                localFile {
                  publicURL
                }
                altText
              }
            }
            competitorTableBody {
              title
              subcopy
              checkboxSimplepractice
              checkboxCompetitor
            }
          }
          testimonialBannerP {
            testimonialBannerAuthor
            testimonialBannerQuote
          }
          featuresModuleP {
            featuresModuleTitle
            featuresModuleSubcopy
            featuresModuleList {
              featuresModuleItemImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [WEBP, JPG, AUTO]
                    )
                  }
                }
              }
              featuresModuleItemTitle
              featuresModuleItemBadge
              featuresModuleItemBadgeColor
              featuresModuleItemSubcopy
              featuresModuleItemSubcopySecondary
              featuresModuleItemTitleSecondary
              featuresModuleItemCta
              featuresModuleItemCtaText
            }
          }
          switchingCtaP {
            switchingCtaBadge
            switchingCtaTitle
            switchingCtaSubcopy
            switchingCtaLink
            switchingCtaLinkText
          }
          contentFaqsP {
            contentFaqsTitle
            contentFaqsSubcopy
            contentFaqsList {
              ... on WpFaq {
                id
                title
                uri
                faqsC {
                  questionsAnswer
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CompetitorPage;
