import React from "react";
import { CompetitorTablePType } from "../../../assets/Types/AcfGroupTypes/CompetitorTableP.type";
import CheckIcon from "../../../components/SVGIcons/Check/Check";
import CrossIcon from "../../../components/SVGIcons/Cross/Cross";

import styles from "./TableBody.module.scss";
interface TableBodyProps {
  body: CompetitorTablePType["competitorTableBody"];
}

const TableBody: React.FC<TableBodyProps> = ({ body }) => {
  return (
    <tbody>
      {body.map((body, index) => {
        return (
          <React.Fragment key={index}>
            <tr>
              <td>
                <p className={styles.rowTitle}>{body.title}</p>
                <p className={styles.rowSubCopy}>{body.subcopy}</p>
              </td>
              <td className={styles.textAlign}>
                <CheckIcon fill="#1371C8" />
              </td>
              <td className={styles.textAlign}>
                {body.checkboxCompetitor === "check" ? (
                  <CheckIcon fill="#1371C8" />
                ) : (
                  <CrossIcon />
                )}
              </td>
            </tr>
          </React.Fragment>
        );
      })}
    </tbody>
  );
};

export default TableBody;
