import React from "react";
import { SwitchingCtaPType } from "../../assets/Types/AcfGroupTypes/SwitchingCtaP.type";
import PrimaryButtonWithArrow from "../../components/Buttons/PrimaryButtonWithArrow/PrimaryButtonWithArrow";

import styles from "./SwitchingCtaPContainer.module.scss";

interface SwitchingCtaPContainerProps {
  switchingCtaP: SwitchingCtaPType;
}

const SwitchingCtaPContainer: React.FC<SwitchingCtaPContainerProps> = ({
  switchingCtaP,
}) => (
  <section className="full-width">
    <div className={styles.switchingCta}>
      <div>
        <p className={styles.switchingLabel}>
          {switchingCtaP.switchingCtaBadge}
        </p>
        <p data-testid="p:switchingCtaTitle" className={styles.primaryText}>
          {switchingCtaP.switchingCtaTitle}
        </p>
        <p className={styles.subCopy}>{switchingCtaP.switchingCtaSubcopy}</p>
      </div>
      <PrimaryButtonWithArrow
        buttonText={switchingCtaP.switchingCtaLinkText}
        buttonLink={switchingCtaP.switchingCtaLink}
        className={styles.switchingButton}
      />
    </div>
  </section>
);

export default SwitchingCtaPContainer;
