import React from "react";
import { CompetitorTablePType, competitorTableHeaderType } from "@types";
import useWindowSize from "../../../hooks/useWindowSize";

import styles from "./TableHead.module.scss";

type TableHeadersType = {
  headers: CompetitorTablePType["competitorTableHeaders"];
};

const TableHead: React.FC<TableHeadersType> = ({ headers }) => {
  const { width: windowWidth } = useWindowSize();
  const isSmallWindowWidth = windowWidth < 768 ? true : false;
  const competitorClassName = isSmallWindowWidth
    ? `${styles.competitorLogo} ${styles.icon}`
    : styles.competitorLogo;
  let getImageUrl = (header: competitorTableHeaderType) => {
    return isSmallWindowWidth
      ? header.imageIcon?.mediaItemUrl
      : header.image?.mediaItemUrl;
  };
  let getImageText = (header: competitorTableHeaderType) => {
    return isSmallWindowWidth
      ? header.imageIcon?.altText
      : header.image?.altText;
  };
  return (
    <thead>
      <tr>
        {headers.map((header, index) => {
          return index === 0 ? (
            <th key={index}>{header.text}</th>
          ) : (
            <th key={index}>
              <div className={competitorClassName}>
                <img src={getImageUrl(header)} alt={getImageText(header)} />
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHead;
