import React from "react";
import { CompetitorHeroPType } from "../../assets/Types/AcfGroupTypes/CompetitorHeroP.type";

import styles from "./CompetitorHeroPContainer.module.scss";

type CompetitorHeroPContainerType = {
  competitorHeroP: CompetitorHeroPType;
};
const CompetitorHeroPContainer: React.FC<CompetitorHeroPContainerType> = ({
  competitorHeroP,
}) => (
  <section
    className={`${styles.competitorGrid} full-width pad-bottom--large-sans-header pad-top--large-sans-header`}
  >
    {competitorHeroP.competitorList &&
      competitorHeroP.competitorList?.map((element, index) => (
        <div className={styles.competitorHeroBox} key={index}>
          <div className={styles.competitorImage}>
            <img
              data-testid={`img:competitorImage${index}`}
              src={element.image.mediaItemUrl}
              alt={element.image.altText}
            />
          </div>
          <h1 className={`${styles.title} pad-bottom--16`}>{element.title}</h1>
          <p>{element.subcopy}</p>
        </div>
      ))}
  </section>
);

export default CompetitorHeroPContainer;
