import React from "react";

const Cross: React.FC<{ className?: string }> = ({ className = "" }) => {
  return (
    <svg
      className="icon"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="cross-icon"
    >
      <path
        d="M21.5 0.516594C21.1886 0.204506 20.7659 0.0291178 20.325 0.0291178C19.8841 0.0291178 19.4614 0.204506 19.15 0.516594L11 8.64993L2.85001 0.499927C2.53863 0.187839 2.11588 0.0124512 1.67501 0.0124512C1.23415 0.0124512 0.811398 0.187839 0.500012 0.499927C-0.149988 1.14993 -0.149988 2.19993 0.500012 2.84993L8.65001 10.9999L0.500012 19.1499C-0.149988 19.7999 -0.149988 20.8499 0.500012 21.4999C1.15001 22.1499 2.20001 22.1499 2.85001 21.4999L11 13.3499L19.15 21.4999C19.8 22.1499 20.85 22.1499 21.5 21.4999C22.15 20.8499 22.15 19.7999 21.5 19.1499L13.35 10.9999L21.5 2.84993C22.1333 2.21659 22.1333 1.14993 21.5 0.516594Z"
        fill="#BBBBBB"
      ></path>
    </svg>
  );
};

export default Cross;
