import React from "react";
import { TestimonialBannerType } from "../../assets/Types/AcfGroupTypes/TestimonialBanner.type";

import styles from "./TestinmonialBannerPContainer.module.scss";

interface TestinmonialBannerPContainerProps {
  testimonialBannerP: TestimonialBannerType;
}
const TestinmonialBannerPContainer: React.FC<TestinmonialBannerPContainerProps> =
  ({ testimonialBannerP }) => (
    <section className="pad-top--large-sans-header">
      <div
        className={`${styles.testimonialBanner} pos--relative pad--for-small`}
      >
        <div className={styles.testimonialWrapper}>
          <q>{testimonialBannerP.testimonialBannerQuote}</q>
          <p className={styles.body}>
            — {testimonialBannerP.testimonialBannerAuthor}
          </p>
        </div>
      </div>
    </section>
  );

export default TestinmonialBannerPContainer;
