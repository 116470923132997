import React from "react";

import TableHead from "./TableHead/TableHead";
import TableBody from "./TableBody/TableBody";
import { CompetitorTablePType } from "../../assets/Types/AcfGroupTypes/CompetitorTableP.type";
import styles from "./CompetitorTablePContainer.module.scss";

interface CompetitorTablePContainerProps {
  competitorTableP: CompetitorTablePType;
}
const CompetitorTablePContainer: React.FC<CompetitorTablePContainerProps> = ({
  competitorTableP,
}) => (
  <section className="full-width">
    <div>
      <table className={styles.competitorTable}>
        <TableHead headers={competitorTableP.competitorTableHeaders} />
        <TableBody body={competitorTableP.competitorTableBody} />
      </table>
    </div>
  </section>
);

export default CompetitorTablePContainer;
